const axios = require('axios');

const MAIL_URL = 'https://mailer.nyfenitra.com/';

exports.validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

exports.sendMail = async (body) =>
  axios({
    method: 'post',
    url: MAIL_URL,
    data: body,
  });
