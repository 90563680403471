import * as React from 'react';
import PropTypes from 'prop-types';

import { md } from '../../helpers/breakpoint';

function Nav({ location, windowWidth, homeHeight, setIsNav }) {
  const line1 = React.useRef(null);
  const line2 = React.useRef(null);
  const navLink = React.useRef(null);
  const scrollValue = React.useRef(0);

  const [isFixedNav, setIsFixedNav] = React.useState(false);

  const hideNav = (bool) => {
    if (bool) {
      line1.current && line1.current.classList.remove('close');
      line2.current && line2.current.classList.remove('close');
      navLink.current.classList.remove('show');
    } else {
      line1.current && line1.current.classList.add('close');
      line2.current && line2.current.classList.add('close');
      navLink.current.classList.add('show');
    }
  };

  const btnOnClick = () => {
    if (windowWidth <= md) {
      hideNav(isFixedNav);
      setIsFixedNav(!isFixedNav);
    }
  };

  // Hide / Show Nav

  const toggleNavOnScroll = React.useCallback(() => {
    if (window.scrollY < scrollValue.current && window.scrollY >= homeHeight) {
      document.querySelector('#nav-principale').classList.add('transform');
      setIsNav(true);
    } else {
      document.querySelector('#nav-principale').classList.remove('transform');
      setIsNav(false);
      hideNav(true);
    }

    scrollValue.current = window.scrollY;
  }, [homeHeight, setIsNav]);

  React.useEffect(() => {
    window.addEventListener('scroll', toggleNavOnScroll);

    return () => {
      window.removeEventListener('scroll', toggleNavOnScroll);
    };
  }, [toggleNavOnScroll]);

  // Transform nav to fixed nor absolute (home only)

  const makeFixed = React.useCallback(() => {
    if (windowWidth > md) {
      if (window.scrollY >= homeHeight) {
        document.querySelector('#nav-principale').classList.remove('absolute');
      } else {
        setTimeout(() => {
          document.querySelector('#nav-principale').classList.add('absolute');
        }, 200);
      }
    }
  }, [homeHeight, windowWidth]);

  React.useEffect(() => {
    window.addEventListener('scroll', makeFixed);

    return () => {
      window.removeEventListener('scroll', makeFixed);
    };
  }, [homeHeight, makeFixed]);

  return (
    <nav id="nav-principale" className={location.pathname && location.pathname === '/' && windowWidth > md ? 'absolute container' : 'sticky container'}>
      <div className="mobile">
        <div className="header">
          <div className="logo" />
          <div role="button" tabIndex={0} aria-label="leftend" className="btn-menu" onClick={btnOnClick} onKeyDown={btnOnClick}>
            <img ref={line1} alt="" className="line-1" src="/menu-line.svg" />
            <img ref={line2} alt="" className="line-2" src="/menu-line.svg" />
          </div>
        </div>
        <div ref={navLink} className="nav-link">
          <a onClick={hideNav.bind(this, true)} href="#home">
            Accueil
          </a>
          <a onClick={hideNav.bind(this, true)} href="#about">
            A propos
          </a>
          <a onClick={hideNav.bind(this, true)} href="#services">
            Mes services
          </a>
          <a onClick={hideNav.bind(this, true)} href="#contact">
            Contact
          </a>
          {/* <a>Blog</a> */}
          {windowWidth <= md ? (
            <div className="social-media-icons">
              <a href="https://web.facebook.com/nyfenitra.rakotomalala/" target="_blank" className="facebook" aria-label="facebook" rel="noreferrer" />
              <a href="https://www.linkedin.com/in/ny-fenitra-rakotomalala/" target="_blank" className="linkedin" rel="noreferrer" aria-label="linkedin" />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="desktop">
        <div className="logo" />
        <div className="nav-link">
          <a href="#home">Accueil</a>
          <a href="#about">A propos</a>
          <a href="#services">Mes services</a>
          <a href="#contact">Contact</a>
          {/* <a>Blog</a> */}
        </div>

        <div className="social-media-icons">
          <a href="https://web.facebook.com/nyfenitra.rakotomalala/" target="_blank" className="facebook" rel="noreferrer" aria-label="facebook" />
          <a href="https://www.linkedin.com/in/ny-fenitra-rakotomalala/" target="_blank" className="linkedin" rel="noreferrer" aria-label="linkedin" />
        </div>
      </div>
    </nav>
  );
}

export default Nav;

Nav.propTypes = {
  location: PropTypes.object.isRequired,
  windowWidth: PropTypes.number.isRequired,
  homeHeight: PropTypes.number.isRequired,
  setIsNav: PropTypes.func.isRequired,
};
