import * as React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import '../../assets/sass/components/service.scss';

function Service({ section }) {
  return (
    <div className="service-item">
      <div className="content">
        <h3>{section.title}</h3>
        <p>{parse(section.content)}</p>
      </div>
      {section.images.map(({ asset }) => (
        <img key={asset.originalFilename} className="img-container" src={`/content/${asset.originalFilename}`} alt={asset.originalFilename} />
      ))}
    </div>
  );
}

export default Service;

Service.propTypes = {
  section: PropTypes.object.isRequired,
};
