import * as React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import '../../assets/sass/components/timeline.scss';

function Timeline({ periods, title, slug }) {
  return (
    <div className="timeline">
      <h3>{title}</h3>
      {periods.map((period) => (
        <React.Fragment key={period.title}>
          <div className="top" style={{ display: slug === 'education' ? 'block' : 'none' }} />
          <div className="period">
            <div className="middle" />
            <div>
              <h4>{period.title}</h4>
              <p>{parse(period.content)}</p>
            </div>
          </div>
          <div className="bottom" />
        </React.Fragment>
      ))}
    </div>
  );
}

export default Timeline;

Timeline.propTypes = {
  periods: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};
