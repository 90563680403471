import * as React from 'react';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import { md } from '../../helpers/breakpoint';
import '../../assets/sass/components/card.scss';

function Card({ images, section, windowWidth }) {
  return (
    <div className="cards">
      <div className="card-1">
        <div className="profile-container">
          {images &&
            images.map(({ asset }, i) => {
              if (windowWidth > md && i === 0) {
                return <img key={asset.originalFilename} className="profile" src={`/content/${asset.originalFilename}`} alt={asset.originalFilename} />;
              }

              if (windowWidth <= md && i === 1) {
                return <img key={asset.originalFilename} className="profile" src={`/content/${asset.originalFilename}`} alt={asset.originalFilename} />;
              }

              return '';
            })}
        </div>
        {section &&
          section.map((subSection) => {
            if (subSection.slug !== 'description') {
              return (
                <div key={subSection.images[0].asset.originalFilename} className="info">
                  <img src={subSection.images ? `/content/${subSection.images[0].asset.originalFilename}` : ''} alt="" />
                  <div className="coordinate">{parse(subSection.content)}</div>
                </div>
              );
            }
            return '';
          })}
        <img src="/support.svg" alt="Support" className="support" />
      </div>
      <div className="card-2">
        <div className="profile-container">
          {images &&
            images.map(({ asset }, i) => {
              if (windowWidth > md && i === 0) {
                return <img key={asset.originalFilename} className="profile" src={`/content/${asset.originalFilename}`} alt={asset.originalFilename} />;
              }

              if (windowWidth <= md && i === 1) {
                return <img key={asset.originalFilename} className="profile" src={`/content/${asset.originalFilename}`} alt="Ny Fenitra" />;
              }

              return '';
            })}
        </div>
        {section &&
          section.map(
            (subSection) =>
              subSection.slug === 'description' && (
                <div key={subSection.content} className="info">
                  <div>{parse(subSection.content)}</div>
                </div>
              )
          )}
        <img src="/support.svg" alt="Support" className="support" />
      </div>
    </div>
  );
}

export default Card;

Card.propTypes = {
  images: PropTypes.array.isRequired,
  section: PropTypes.array.isRequired,
  windowWidth: PropTypes.number.isRequired,
};
