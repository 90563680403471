/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Script } from 'gatsby';

function Seo({ description, title, children, keyword }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            meta_keyword
            siteUrl
            img
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const metaKeyword = keyword || site.siteMetadata.meta_keyword;
  const metaOg = `${site.siteMetadata.siteUrl}/${site.siteMetadata.img}`;
  const GA_TRAKING_ID = 'G-J5G3K4GPS6';

  return (
    <>
      <title>{title || defaultTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="meta_keyword" content={metaKeyword} />
      <meta name="viewport" content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0 " />
      <meta property="og:image" content={metaOg} />
      <Script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRAKING_ID}`} strategy="off-main-thread" />
      <Script id="gtag-config" strategy="off-main-thread" forward={['gtag']}>
        {`
          window.dataLayer = window.dataLayer || []
          function gtag() { window.dataLayer.push(arguments) }
          gtag('js', new Date())
          gtag('config', '${GA_TRAKING_ID}')
        `}
      </Script>

      {children}
    </>
  );
}

Seo.defaultProps = {
  description: '',
  title: '',
  children: '',
  keyword: '',
};

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  keyword: PropTypes.string,
};

export default Seo;
