import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import { md, figma } from '../helpers/breakpoint';
import { validateEmail, sendMail } from '../helpers/utils';

import Nav from '../components/nav';
import Seo from '../components/seo';
import Service from '../components/service';
import Timeline from '../components/timeline';
import Card from '../components/card';

import '../assets/sass/pages/index.scss';

function HomePage({ location }) {
  const dark = React.useRef(null);
  const leftend = React.useRef(null);
  const rightend = React.useRef(null);
  const alert = React.useRef(null);

  const [windowWidth, setWindowWidth] = React.useState(1000);
  const [homeHeight, setHomeHeight] = React.useState(0);
  const [isNav, setIsNav] = React.useState(false);

  const [isSending, setIsSending] = React.useState(false);

  const {
    sanity: { allHomeContent: homeContent },
  } = useStaticQuery(
    graphql`
      query {
        sanity {
          allHomeContent {
            slug
            title
            content
            images {
              asset {
                originalFilename
              }
            }
            subSection {
              slug
              title
              content
              images {
                asset {
                  originalFilename
                }
              }
              subSection {
                slug
                title
                content
              }
            }
          }
        }
      }
    `
  );

  const getContent = (slug) => homeContent.filter((item) => item.slug === slug)[0];

  // Resize dark

  const position = React.useRef({
    leftend: false,
    rightend: false,
  });

  const resize = (pos) => {
    position.current[pos] = true;
  };

  const performResize = (e) => {
    if (position.current.leftend) {
      // Get mouse position relative to dark
      const mousePosXLeft = e.clientX - dark.current.getBoundingClientRect().x;

      let left = 0;

      if (mousePosXLeft <= 0) {
        left = 0;
      } else if (mousePosXLeft >= dark.current.getBoundingClientRect().width) {
        left = dark.current.getBoundingClientRect().width;
      } else {
        left = mousePosXLeft;
      }

      leftend.current.style.setProperty('left', `${left}px`, 'important');
      dark.current.style.setProperty('clip-path', `inset(0px ${getComputedStyle(rightend.current).right} 0px ${left}px)`, 'important');
    } else if (position.current.rightend) {
      const mousePosXRight = dark.current.getBoundingClientRect().width - (e.clientX - dark.current.getBoundingClientRect().x);

      let right = 0;

      if (mousePosXRight <= 0) {
        right = 0;
      } else if (mousePosXRight >= dark.current.getBoundingClientRect().width) {
        right = dark.current.getBoundingClientRect().width;
      } else {
        right = mousePosXRight;
      }

      rightend.current.style.setProperty('right', `${right}px`, 'important');
      dark.current.style.setProperty('clip-path', `inset(0px ${right}px 0px ${getComputedStyle(leftend.current).left})`, 'important');
    }
  };

  const done = () => {
    position.current = {
      leftend: false,
      rightend: false,
    };
  };

  const removeError = (input) => {
    document.querySelector(`.form-group.${input}`).classList.remove('error');
    document.querySelector(`.form-group.${input} .error-message`).innerHTML = '';
    document.querySelector(`.form-group.${input} .error-message`).style.display = 'none';
  };

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    setHomeHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener('mousemove', performResize);
    window.addEventListener('mouseup', done);

    resizeWindow();

    window.addEventListener('resize', resizeWindow);

    return () => {
      window.removeEventListener('resize', resizeWindow);
      window.removeEventListener('mousemove', performResize);
      window.removeEventListener('mouseup', done);
    };
  }, []);

  React.useEffect(() => {
    if (dark.current && homeHeight < dark.current.getBoundingClientRect().height) {
      setHomeHeight(dark.current.getBoundingClientRect().height);
    }
  }, [homeHeight]);

  React.useEffect(() => {
    if (isNav && windowWidth > md) {
      document.querySelector('.cards').classList.add('top-plus');
    } else {
      document.querySelector('.cards').classList.remove('top-plus');
    }
  }, [isNav, windowWidth]);

  // Sending mail

  const send = async (e) => {
    e.preventDefault();
    setIsSending(true);

    let hasError = false;
    const form = e.currentTarget;

    const name = form.querySelector('input[name="name"]').value;
    const email = form.querySelector('input[name="email"]').value;
    const number = form.querySelector('input[name="number"]').value;
    const message = form.querySelector('textarea[name="message"]').value;

    Object.entries({
      name,
      email,
      message,
    }).forEach(([input, value]) => {
      if (!value) {
        form.querySelector(`.${input}`).classList.add('error');
        form.querySelector(`.${input} .error-message`).innerHTML = `Veuillez entrer votre ${form.querySelector(`.${input}`).dataset.label}`;
        form.querySelector(`.${input} .error-message`).style.display = 'block';
        hasError = true;
      } else if (input === 'email' && !validateEmail(value)) {
        form.querySelector(`.${input}`).classList.add('error');
        form.querySelector(`.${input} .error-message`).innerHTML = `Veuillez entrer un ${form.querySelector(`.${input}`).dataset.label} valide`;
        form.querySelector(`.${input} .error-message`).style.display = 'block';
        hasError = true;
      }
    });

    if (!hasError) {
      try {
        const result = await sendMail({ name, email, number, message });
        if (result.data.success) {
          form.querySelector('input[name="name"]').value = '';
          form.querySelector('input[name="email"]').value = '';
          form.querySelector('input[name="number"]').value = '';
          form.querySelector('textarea[name="message"]').value = '';

          alert.current.querySelector('.message').innerHTML = 'Mail Envoyé avec succès ! Je vous répondrai dans les brefs délais. Merci !';
          alert.current.querySelector('img').src = '/Check.svg';
        } else {
          alert.current.querySelector('.message').innerHTML = 'Service temporairement indisponible ! Veuillez réessayer plus tard svp';
          alert.current.querySelector('img').src = '/Unheck.svg';
        }
      } catch (error) {
        console.error(error);
        alert.current.querySelector('.message').innerHTML = 'Service temporairement indisponible ! Veuillez réessayer plus tard svp';
        alert.current.querySelector('img').src = '/Uncheck.svg';
      }

      alert.current.parentNode.classList.add('show-alert');

      setTimeout(() => alert.current.parentNode.classList.remove('show-alert'), 5000);
    }

    setIsSending(false);
  };

  return (
    <>
      <Nav location={location} windowWidth={windowWidth} setIsNav={setIsNav} homeHeight={homeHeight} />

      <div id="home">
        <div className="light">
          <div className="presentation">
            <div>
              <h1 className="name">
                <div className="logo" />
                <div className="text">y Fenitra</div>
              </h1>
              <div className="status">{getContent('home') ? parse(getContent('home').content) : ''}</div>
              <svg width="85" height="5" viewBox="0 0 85 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.00385933" y1="2.20258" x2="84.8273" y2="2.38471" stroke="#F2D058" strokeWidth="3.59485" />
              </svg>
            </div>
            <img className="nyfenitra" src={getContent('home') ? `/content/${getContent('home').images[0].asset.originalFilename}` : ''} alt="Ny Fenitra" width={360} height={542} />
            <svg className="first illustrations" width="219" height="219" viewBox="0 0 219 219" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M124.647 72.7581C143.919 66.9933 162.205 84.2526 157.561 103.825L150.422 133.917C145.779 153.49 121.689 160.696 107.06 146.888L84.5691 125.659C69.9407 111.852 75.7449 87.3864 95.0168 81.6216L124.647 72.7581Z" fill="#373737" />
              <path d="M136.291 100.954L129.152 131.046C124.602 150.225 100.996 157.286 86.662 143.756L64.1708 122.527C49.8364 108.997 55.524 85.0237 74.4085 79.3748L104.039 70.5113C122.923 64.8623 140.841 81.7748 136.291 100.954Z" stroke="#F2D058" strokeWidth="1.05046" />
            </svg>
            <svg className="second illustrations" width="243" height="236" viewBox="0 0 243 236" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M122.402 82.1204L151.945 103.817C170.773 117.644 167.572 146.669 146.183 156.062L112.622 170.798C91.2324 180.19 67.6966 162.906 70.2575 139.686L74.2757 103.253C76.8365 80.0328 103.573 68.2926 122.402 82.1204Z" stroke="#373737" strokeWidth="1.24494" />
              <path d="M89.7978 86.727C92.4112 63.0308 119.697 51.0497 138.911 65.1611L168.454 86.8574C187.669 100.969 184.402 130.589 162.574 140.174L129.013 154.911C107.185 164.495 83.1662 146.856 85.7797 123.16L89.7978 86.727Z" fill="#F2D058" />
            </svg>
          </div>
        </div>

        <div className="dark" ref={dark}>
          <nav>
            <div className="desktop">
              <div className="logo" />
              <div className="nav-link">
                <a href="#home">Accueil</a>
                <a href="#about">A propos</a>
                <a href="#services">Mes services</a>
                <a href="#contact">Contact</a>
                {/* <a>Blog</a> */}
              </div>
              <div className="social-media-icons">
                <a href="https://web.facebook.com/nyfenitra.rakotomalala/" target="_blank" className="facebook" rel="noreferrer" aria-label="facebook" />
                <a href="https://www.linkedin.com/in/ny-fenitra-rakotomalala/" target="_blank" className="linkedin" rel="noreferrer" aria-label="linkedin" />
              </div>
            </div>
          </nav>

          <div className="presentation">
            <div>
              <h1 className="name">
                <div className="logo" />
                <div className="text">y Fenitra</div>
              </h1>
              <div className="status">{getContent('home') ? parse(getContent('home').content) : ''}</div>
              <svg width="85" height="5" viewBox="0 0 85 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.00385933" y1="2.20258" x2="84.8273" y2="2.38471" stroke="#F2D058" strokeWidth="3.59485" />
              </svg>
            </div>
            <img className="nyfenitra" src={getContent('home') ? `/content/${getContent('home').images[0].asset.originalFilename}` : ''} alt="Ny Fenitra" width={360} height={542} />
            <svg className="first illustrations" width="219" height="219" viewBox="0 0 219 219" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M124.647 72.7581C143.919 66.9933 162.205 84.2526 157.561 103.825L150.422 133.917C145.779 153.49 121.689 160.696 107.06 146.888L84.5691 125.659C69.9407 111.852 75.7449 87.3864 95.0168 81.6216L124.647 72.7581Z" fill="white" />
              <path d="M136.291 100.954L129.152 131.046C124.602 150.225 100.996 157.286 86.6619 143.756L64.1707 122.527C49.8363 108.997 55.5239 85.0237 74.4084 79.3748L104.039 70.5113C122.923 64.8623 140.841 81.7748 136.291 100.954Z" stroke="#F2D058" strokeWidth="1.05046" />
            </svg>
            <svg className="second illustrations" width="243" height="236" viewBox="0 0 243 236" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M122.402 82.1204L151.945 103.817C170.773 117.644 167.572 146.669 146.183 156.062L112.622 170.798C91.2324 180.19 67.6967 162.906 70.2576 139.686L74.2757 103.253C76.8366 80.0328 103.573 68.2926 122.402 82.1204Z" stroke="white" strokeWidth="1.24494" />
              <path d="M89.7978 86.727C92.4112 63.0308 119.697 51.0497 138.911 65.1611L168.454 86.8574C187.669 100.969 184.402 130.589 162.574 140.174L129.013 154.911C107.185 164.495 83.1662 146.856 85.7797 123.16L89.7978 86.727Z" fill="#F2D058" />
            </svg>
          </div>
        </div>
        <div ref={leftend} className="leftend" onMouseDown={resize.bind(this, 'leftend')} role="button" tabIndex={0} aria-label="leftend" />
        <div ref={rightend} className="rightend" onMouseDown={resize.bind(this, 'rightend')} role="button" tabIndex={0} aria-label="rightend" />
      </div>

      <section id="about">
        {windowWidth <= md ? <h2>{getContent('about') ? getContent('about').title : ''}</h2> : ''}
        <Card windowWidth={windowWidth} images={getContent('about') && getContent('about').images} section={getContent('about') && getContent('about').subSection} />
        <div className="content">
          {windowWidth > md ? <h2>{getContent('about') ? getContent('about').title : ''}</h2> : ''}
          <p>{getContent('about') ? parse(getContent('about').content) : ''}</p>
          <div className="resume">{getContent('resume') && getContent('resume').subSection.map((section) => <Timeline key={section.slug} slug={section.slug} title={section.title} periods={section.subSection} />)}</div>
        </div>
      </section>

      <section id="stacks">
        <h2>{getContent('stacks') ? getContent('stacks').title : ''}</h2>
        <div className="images">{getContent('stacks') && getContent('stacks').images.map(({ asset }, i) => <img key={asset.originalFilename} className={`stack-${i + 1}`} src={`/content/${asset.originalFilename}`} alt={asset.originalFilename} />)}</div>
      </section>

      <section id="services">
        <h2>{getContent('services') ? getContent('services').title : ''}</h2>
        <p>{getContent('services') ? parse(getContent('services').content) : ''}</p>

        {getContent('services') && getContent('services').subSection.map((section) => <Service key={section.title} section={section} />)}
      </section>

      <section id="contact">
        <h2>{getContent('contact') ? getContent('contact').title : ''}</h2>
        <div className="pictos">
          {getContent('contact') &&
            getContent('contact').subSection.map((section) => (
              <div key={section.images[0].asset.originalFilename} className="picto">
                <img className="icon" src={`/content/${section.images[0].asset.originalFilename}`} alt="" />
                <span className="text">{section.content}</span>
              </div>
            ))}
        </div>
        <div className="form-container">
          <form onSubmit={send}>
            <div className="form-group name" data-label="nom">
              <label htmlFor="name">
                Nom *
                <input id="name" onFocus={removeError.bind(this, 'name')} className="input" type="text" name="name" />
              </label>
              <span className="error-message" />
            </div>
            <div className="form-group email" data-label="email">
              <label htmlFor="email">
                Email *
                <input id="email" onFocus={removeError.bind(this, 'email')} className="input" type="text" name="email" />
              </label>
              <span className="error-message" />
            </div>
            <div className="form-group number" data-label="numero">
              <label htmlFor="number">
                Numero
                <input id="number" onFocus={removeError.bind(this, 'number')} className="input" type="text" name="number" />
              </label>
              <span className="error-message" />
            </div>
            <div className="form-group message" data-label="message">
              <label htmlFor="message">
                Votre message *
                <textarea id="message" onFocus={removeError.bind(this, 'message')} className="input" name="message" />
              </label>
              <span className="error-message" />
            </div>
            <div className="send-container">
              {isSending ? (
                <div className="lds-ellipsis">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              ) : (
                <button className="input send" type="submit">
                  Envoyer
                </button>
              )}
            </div>
            <img src="/support.svg" alt="Support" className="support" />
          </form>

          <div ref={alert} className="alert">
            <div className="message">Mail Envoyé avec succès ! Je vous répondrai dans les brefs délais. Merci !</div>
            <img src="/Check.svg" alt="" />
            <img src="/support.svg" alt="Support" className="support" />
          </div>
        </div>
        {windowWidth >= figma ? [1, 2, 3].map((nb) => <img alt="" className="paper" id={`paper-${nb}`} key={nb} src={`paper${nb}.svg`} />) : ''}
      </section>
    </>
  );
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */

export function Head() {
  return <Seo />;
}

export default HomePage;

HomePage.propTypes = {
  location: PropTypes.object.isRequired,
};
